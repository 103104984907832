import { render } from "./PostLogin.vue?vue&type=template&id=3c4a4803"
import script from "./PostLogin.vue?vue&type=script&lang=js"
export * from "./PostLogin.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3c4a4803"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3c4a4803', script)) {
    api.reload('3c4a4803', script)
  }
  
  module.hot.accept("./PostLogin.vue?vue&type=template&id=3c4a4803", () => {
    api.rerender('3c4a4803', render)
  })

}

script.__file = "src/components/login/PostLogin.vue"

export default script